import React from 'react';
import { inject, observer } from 'mobx-react';

import '../assets/css/selectPaymentMethodSaved.css';

@inject('vTerminal', 'global')
@observer
class SelectPaymentMethodSaved extends React.Component {
  constructor(props) {
    super(props);
    this.myRefInput = React.createRef();

    this.state = {
      value: null,
      dropdownOpen: false,
      some: true,
    };
    this.handlePickCard = this.handlePickCard.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.vTerminal.paymentMethod !== 'savedcard' && props.vTerminal.paymentMethod !== 'savedach') return { value: null };
    return null;
  }

  componentDidMount(){
    const objComponent = document.getElementById("payabli-select-pm");
    document.addEventListener("click", (event) => {
      if(objComponent && !objComponent.contains(event.target)){
          if(this.state.dropdownOpen === true){
            this.hideDropDown();
          }
      }
    });
  }

  hideDropDown() {
    this.setState({ dropdownOpen: false });
  }

  showDropDown(){
    this.setState({ dropdownOpen: true });
  }

  handlePickCard(cardData, pos) {
    const value = cardData.IdPmethod + '|' + cardData.Method + '|' + pos;
    let data = value.split('|');
    this.props.vTerminal.setSavedPaymentMethodSelected(value);
    if (data.length === 3) {
      this.props.vTerminal.handleTextChangeACH(
        'paymentMethodSaved',
        cardData.IdPmethod
      );
      this.props.vTerminal.setPaymentMethod('saved' + cardData.Method);
      this.props.vTerminal.setDefaultPaymentMethodActiveKey('2');
      this.props.vTerminal.setPaymentMethodSavedPosition(pos);

      this.setState({
        value: {
          cardImage: this.props.global.getPaymethodImg(cardData.Descriptor),
          cardBrandName: this.props.global.capitalizeFirstLetter(
            cardData.Descriptor
          ),
          cardNumberMasked: this.props.global.maskedCardNumber(
            cardData.MaskedAccount,
            'v6'
          ),
          cardHolderName: cardData.HolderName,
        },
      }, this.hideDropDown());
    } else {
      this.props.vTerminal.handleTextChangeACH('paymentMethodSaved', null);
      this.hideDropDown()
    }
  }

  render() {
    let credentialsPermissions = this.props.vTerminal.getCredentialsPermissions;
    return (
      <div id="payabli-select-pm" >
        {this.props.vTerminal.getCustomerSelected.StoredMethods &&
          this.props.vTerminal.getCustomerSelected.StoredMethods.length > 0 && (
            <div className="form-floating ">
              <div
                className="select-dropdown cursor-pointer"
                onClick={(e) => this.state.dropdownOpen ? this.hideDropDown() : this.showDropDown()}
              >
                <input
                  className={
                    this.props.vTerminal.getPaymentPageErrors
                      .paymentMethodsSavedError
                      ? 'form-control form-select input-error'
                      : 'form-control form-select'
                  }
                  ref={this.myRefInput}
                  autoComplete="off"
                  readOnly
                  id="savedPaymentMethod"
                />

                {this.state.value && (
                  <div className="selected-value row-card">
                    <div style={{marginRight: "10px"}}>
                    {this.state.value.cardImage}
                    </div>
                    <span className="card-brand">
                      {this.state.value.cardBrandName}
                    </span>
                    <span className="card-number-masked">
                      {this.state.value.cardNumberMasked}
                    </span>
                    <span className="card-holder">
                      {this.state.value.cardHolderName}
                    </span>
                  </div>
                )}

                {this.state.value === null && (
                  <div className="placeholder">
                    Select a stored payment method
                  </div>
                )}
              </div>

              <div
                className={
                  this.state.dropdownOpen
                    ? 'search-dropdown-menu'
                    : 'd-none search-dropdown-menu'
                }
              >
                {this.props.vTerminal.getCustomerSelected.StoredMethods.map(
                  (record, i) =>
                    ((credentialsPermissions[record.Method.toLowerCase()]
                      .onetime &&
                      !this.props.vTerminal.isAutopay) ||
                      (credentialsPermissions[record.Method.toLowerCase()]
                        .recurring &&
                        this.props.vTerminal.isAutopay)) && (
                      <div
                        key={i}
                        className="row-card"
                        onClick={() => this.handlePickCard(record, i)}
                      >
                        <div className="img-cont">
                          {this.props.global.getPaymethodImg(record.Descriptor)}
                        </div>
                        <span className="card-brand">
                          {this.props.global.capitalizeFirstLetter(
                            record.Descriptor
                          )}
                        </span>
                        <span className="card-number-masked">
                          {this.props.global.maskedCardNumber(
                            record.MaskedAccount,
                            'v6'
                          )}
                        </span>
                        <span className="card-holder">{record.HolderName}</span>
                      </div>
                    )
                )}

                {this.props.vTerminal.getCustomerSelected.StoredMethods
                  .length === 0 && (
                  <div className="row-card empty">
                    Not stored payment method found
                  </div>
                )}
              </div>
            </div>
          )}
      </div>
    );
  }
}

export { SelectPaymentMethodSaved };
