import React from 'react';
import { inject, observer } from 'mobx-react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

@inject('vTerminal','global')
@observer
class AutopayForm extends React.Component {

    constructor(props) {
        super(props);
        this.handleAutopayStartDate = this.handleAutopayStartDate.bind(this);
        this.handleAutopayEndDate = this.handleAutopayEndDate.bind(this);
        this.handleAutopayFrequency = this.handleAutopayFrequency.bind(this);
        this.handleAutopayEndDateAction = this.handleAutopayEndDateAction.bind(this);
        this.endDateAutopayCalendar = React.createRef();
    }

    handleAutopayStartDate(date){
        let enddate = this.props.vTerminal.getAutopayEndDate();
        if(enddate){
            if(enddate <= date){
                enddate.setDate(date.getDate() + 1);
                this.handleAutopayEndDate(enddate);

            }
        }        
        this.props.vTerminal.handleAutopayStartDate(date)
    }

    handleAutopayEndDate(date){
        this.props.vTerminal.handleAutopayEndDate(date);
    }

    handleAutopayFrequency(e){
        this.props.vTerminal.handleAutopayFrequency(e.target.value)
    }

    handleAutopayEndDateAction(value){
        this.props.vTerminal.handleAutopayEndDate(value);
        this.endDateAutopayCalendar.current.setOpen(false)
    }

    render() {

        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        
        let nextTwoDays = new Date();
        nextTwoDays.setDate(nextTwoDays.getDate() + 2);
        if(this.props.vTerminal.paymentPage.autopay.startDate){
            nextTwoDays = new Date(this.props.vTerminal.paymentPage.autopay.startDate);
            nextTwoDays.setDate(nextTwoDays.getDate() + 1);
        }
        const years = [];
        const yearsEndDate = [];
        const months = this.props.global.getMonths();
        let year = new Date().getFullYear();
        for (let i = year; i <= year + 2; i++) {
            years.push(i);
        }
        for (let i = year; i <= year + 20; i++) {
            yearsEndDate.push(i);
        }

        return (
            <div className="row">
                <div className="col-sm mb-3">
                   <DatePicker
                        customInput={
                            <div className="form-floating">
                                <input readOnly autoComplete="off" onChange={function () { }} value={this.props.vTerminal.paymentPage.autopay.startDate ? this.props.vTerminal.dateStringFormat(this.props.vTerminal.paymentPage.autopay.startDate) : ''}  name="startDateAutopayCalendar" id="startDateAutopayCalendar"  className={this.props.vTerminal.getPaymentPageErrors.autopayStartDateError ? "form-control input-calendar input-error input-lg" : "form-control input-calendar input-lg" } placeholder="Start Date" />
                                <label htmlFor="startDateAutopayCalendar">{this.props.vTerminal.paymentPage.autopay.frequencySelected !== 'onetime' ? "Start Date" : "Pay Date"}</label>
                            </div>
                        }
                        selected={this.props.vTerminal.paymentPage.autopay.startDate ? this.props.vTerminal.paymentPage.autopay.startDate : ''}
                        onChange={date => this.handleAutopayStartDate(date)}
                        minDate={tomorrow}
                        dayClassName={date => "calendar-day"}
                        popperPlacement="bottom-start"
                        renderCustomHeader={({
                            date,
                            changeYear,
                            changeMonth,
                            decreaseMonth,
                            increaseMonth,
                            prevMonthButtonDisabled,
                            nextMonthButtonDisabled,
                        }) => (
                            <div
                                style={{
                                    margin: 10,
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                    {"<"}
                                </button>
                                <select
                                    value={date.getFullYear()}
                                    onChange={({ target: { value } }) => changeYear(value)}
                                >
                                    {years.map((option) => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>

                                <select
                                    value={months[date.getMonth()]}
                                    onChange={({ target: { value } }) =>
                                        changeMonth(months.indexOf(value))
                                    }
                                >
                                    {months.map((option) => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>

                                <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                    {">"}
                                </button>
                            </div>
                        )}
                    />
                </div>
                <div className="col-sm">
                    <div className="form-floating mb-3">
                    <select value={this.props.vTerminal.paymentPage.autopay.frequencySelected ? this.props.vTerminal.paymentPage.autopay.frequencySelected : ''}  onChange={(e) => this.handleAutopayFrequency(e)} className={this.props.vTerminal.getPaymentPageErrors.autopayFrequencyError ? "form-select form-control input-error" : "form-select form-control" } id="floatingSelectGrid" aria-label="Frequency">
                        <option defaultValue="select" value=""> Select... </option>
                        { this.props.vTerminal.paymentPage.autopay.frequency.onetime && ( <option value="onetime">Onetime</option> )}
                        { this.props.vTerminal.paymentPage.autopay.frequency.weekly && ( <option value="weekly">Weekly</option> )}
                        { this.props.vTerminal.paymentPage.autopay.frequency.every2Weeks && ( <option  value="every2weeks">Every 2 weeks</option> )}
                        { this.props.vTerminal.paymentPage.autopay.frequency.every6Months && ( <option  value="every6months">Every 6 months</option> )}
                        { this.props.vTerminal.paymentPage.autopay.frequency.monthly && ( <option  value="monthly">Monthly</option> )}
                        { this.props.vTerminal.paymentPage.autopay.frequency.every3Months && ( <option  value="every3months">Every 3 months</option> )}
                        { this.props.vTerminal.paymentPage.autopay.frequency.annually && ( <option  value="annually">Annually</option> )}
                    </select>
                    <label htmlFor="floatingSelectGrid">Frequency</label>
                    </div>
                </div>
                {this.props.vTerminal.paymentPage.autopay.frequencySelected !== 'onetime' &&
                <div className="col-sm">
                        <>
                        <DatePicker
                        customInput={
                            <div className="form-floating">
                                <input readOnly autoComplete="off" onChange={function () { }} 
                                    value={   
                                            this.props.vTerminal.paymentPage.autopay.finishSelected && this.props.vTerminal.paymentPage.autopay.finishSelected !== 'untilcancelled' ? 
                                            this.props.vTerminal.dateStringFormat( new Date(this.props.vTerminal.paymentPage.autopay.finishSelected)) : 
                                            this.props.vTerminal.paymentPage.autopay.finishSelected === 'untilcancelled' ? "Until Cancelled" : ''
                                          }  
                                    name="endDateAutopayCalendar" id="endDateAutopayCalendar" className={this.props.vTerminal.getPaymentPageErrors.autopayFinishError ? "form-control input-calendar input-error input-lg" : "form-control input-calendar input-lg" } placeholder="End Date" />
                                <label htmlFor="endDateAutopayCalendar">End Date</label>
                            </div>
                        }
                        selected={ this.props.vTerminal.paymentPage.autopay.finishSelected && this.props.vTerminal.paymentPage.autopay.finishSelected instanceof Date ? this.props.vTerminal.paymentPage.autopay.finishSelected : ''}
                        onChange={date => this.handleAutopayEndDate(date)}
                        minDate={nextTwoDays}
                        ref={this.endDateAutopayCalendar}
                        dayClassName={date => "calendar-day"}
                        popperPlacement="bottom-end"
                        renderCustomHeader={({
                            date,
                            changeYear,
                            changeMonth,
                            decreaseMonth,
                            increaseMonth,
                            prevMonthButtonDisabled,
                            nextMonthButtonDisabled,
                        }) => (
                            <div
                                style={{
                                    margin: 10,
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                    {"<"}
                                </button>
                                <select
                                    value={date.getFullYear()}
                                    onChange={({ target: { value } }) => changeYear(value)}
                                >
                                    {yearsEndDate.map((option) => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>

                                <select
                                    value={months[date.getMonth()]}
                                    onChange={({ target: { value } }) =>
                                        changeMonth(months.indexOf(value))
                                    }
                                >
                                    {months.map((option) => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>

                                <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                    {">"}
                                </button>
                            </div>
                        )}
                        >
                        <div className="linkCalendar" onClick={(e)=>this.handleAutopayEndDateAction('untilcancelled')}>Until Cancelled</div>
                        </DatePicker>

                        </>
                

                </div>
                }
            </div>
        );
    }
}

export { AutopayForm };