import React from 'react';
import { inject, observer } from 'mobx-react';
import {IMaskInput} from 'react-imask';

@inject('vTerminal', 'global')
@observer
class PaymentMethodCCForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            ccType: 'unknown'
        };
        this.goToReview = this.goToReview.bind(this);
        this.handleTextChangeCC = this.handleTextChangeCC.bind(this);

        this.cardExpirationDate = React.createRef();
        this.cardCvv = React.createRef();
        this.cardZipcode = React.createRef();
    }

    goToReview(event){
        event.preventDefault();
        this.props.history.push('/review-confirm');
    }
    
    handleTextChangeCC(field, value) {
        this.props.vTerminal.handleTextChangeACH(field, value);
        let ccType =  this.props.global.creditCardType(this.props.vTerminal.paymentPage.paymentMethods.cardNumber);
        this.setState({'ccType': ccType});

        let validators = this.props.global.validators;
        
        if(field === "cardExpirationDate" && value!=='' && !validators.stringValidator('exp', value)){
            this.cardCvv.current.element.focus()
        }
        
        if(field === "cardCvv" && value!=='' && (ccType === "american-express" ? !validators.stringValidator('cvvamex', this.props.vTerminal.paymentPage.paymentMethods.cardCvv) : !validators.stringValidator('cvv', this.props.vTerminal.paymentPage.paymentMethods.cardCvv))){
            this.cardZipcode.current.element.focus()
        }

        if(field === "cardCvv" && value === ''){
            this.cardExpirationDate.current.element.focus()
        }

        if(field === "cardZipcode" && value === ''){
            this.cardCvv.current.element.focus()
        }
    }

    

    render() {
        let globalConfig = this.props.config;
        return (
            <div>
                <div className={this.props.vTerminal.hasCCError ? "row cc-form mb-3 input-error" : "row cc-form mb-3" }>
                    <div className="col-sm-7 cc-field cardNumberContainer">
                        <div className={this.state.ccType}>
                            <div className="form-floating input-cc-cont">
                                <IMaskInput
                                    mask={this.state.ccType === "american-express" ? this.props.global.maskValidator('creditCardAmex') : this.props.global.maskValidator('creditCard') }
                                    name="cardNumber"
                                    value={this.props.vTerminal.paymentPage.paymentMethods.cardNumber ? this.props.vTerminal.paymentPage.paymentMethods.cardNumber: ''}
                                    unmask={true}
                                    onAccept={
                                        (value, mask) => this.handleTextChangeCC('cardNumber', value)
                                    }
                                    placeholder="Card Number"
                                    className={"form-control input-cc " + (this.props.vTerminal.paymentPageErrors.paymentMethodsCardNumberError ? "component-input-error" : "") } 
                                    id="cardNumber"
                                    inputmode="numeric"
                                    pattern="[0-9]*"
                                />
                                <label htmlFor="cardNumber">Card Number</label>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm cc-field cardExpirationDateContainer">
                        <div className="form-floating">
                            <IMaskInput
                                mask={this.props.global.maskValidator('cardDate')}
                                name="cardExpirationDate"
                                value={this.props.vTerminal.paymentPage.paymentMethods.cardExpirationDate ? this.props.vTerminal.paymentPage.paymentMethods.cardExpirationDate: ''}
                                unmask={true}
                                onAccept={
                                    (value, mask) => this.handleTextChangeCC('cardExpirationDate', value)
                                }
                                className={"form-control " + (this.props.vTerminal.paymentPageErrors.paymentMethodsCardExpirationDateError ? "component-input-error" : "") } 
                                placeholder="MM/YY"
                                id="cardExpirationDate"
                                ref={this.cardExpirationDate}
                                inputmode="numeric"
                                pattern="[0-9]*"
                            />
                            <label htmlFor="cardExpirationDate">MM/YY</label>
                        </div>
                    </div>
                    <div className="col-sm cc-field cardCvvContainer">
                        <div className="form-floating">
                            <IMaskInput
                                mask={this.state.ccType === "american-express" ? this.props.global.maskValidator('cvvamex') : this.props.global.maskValidator('cvv') }
                                name="cardCvv"
                                id="cardCvv"
                                value={this.props.vTerminal.paymentPage.paymentMethods.cardCvv ? this.props.vTerminal.paymentPage.paymentMethods.cardCvv: ''}
                                unmask={true}
                                onAccept={
                                    (value, mask) => this.handleTextChangeCC('cardCvv', value)
                                }
                                className={"form-control " + (this.props.vTerminal.paymentPageErrors.paymentMethodsCardCvvError ? "component-input-error" : "") } 
                                placeholder="CVV"
                                ref={this.cardCvv}
                                inputmode="numeric"
                                pattern="[0-9]*"
                            />
                            <label htmlFor="cardCvv">CVV</label>
                        </div>
                    </div>
                    <div className="col-sm-2 cc-field cardZipcodeContainer">
                        <div className="form-floating">
                            <IMaskInput
                                mask={ globalConfig && globalConfig.card && globalConfig.card.inputs && globalConfig.card.inputs.cardZipcode && globalConfig.card.inputs.cardZipcode.country ? this.props.global.maskValidator('alphanumeric') : this.props.global.maskValidator('zipcode')}
                                name="cardZipcode"
                                id="cardZipcode"
                                value={this.props.vTerminal.paymentPage.paymentMethods.cardZipcode ? this.props.vTerminal.paymentPage.paymentMethods.cardZipcode: ''}
                                unmask={true}
                                onAccept={
                                    (value, mask) => this.handleTextChangeCC('cardZipcode', value)
                                }
                                className={"form-control " + (this.props.vTerminal.paymentPageErrors.paymentMethodsCardZipcodeError ? "component-input-error" : "") } 
                                placeholder="Zipcode"
                                ref={this.cardZipcode}
                                maxLength={globalConfig && globalConfig.card && globalConfig.card.inputs && globalConfig.card.inputs.cardZipcode && globalConfig.card.inputs.cardZipcode.country ? this.props.global.getZipcodeMaxLength(globalConfig.card.inputs.cardZipcode.country) : 5}
                            />
                            <label htmlFor="cardZipcode">Zipcode</label>
                        </div>
                    </div>
                </div>
                <div className="form-floating">
                    <IMaskInput
                        mask={this.props.global.maskValidator('alpha')}
                        name="cardHolderName"
                        value={this.props.vTerminal.paymentPage.paymentMethods.cardHolderName ? this.props.vTerminal.paymentPage.paymentMethods.cardHolderName: ''}
                        unmask={true}
                        onAccept={
                            (value, mask) => this.handleTextChangeCC('cardHolderName', value)
                        }
                        placeholder="Cardholder Name"
                        className={this.props.vTerminal.getPaymentPageErrors.paymentMethodsCardHolderNameError ? "form-control input-error component-input-error" : "form-control" }
                        id="cardHolderName"
                    />
                    <label htmlFor="cardHolderName">Cardholder Name</label>
                </div>
           
            </div>
        );
    }
}

export { PaymentMethodCCForm };