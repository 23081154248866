import React from 'react';
import { observer } from 'mobx-react';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

@observer
class ErrorMessage extends React.Component {

    render() {
        return (
            <>
            {this.props.value &&
            <div className="small errorMessageComponent">
                {this.props.showPopoverError===true ?
                <OverlayTrigger
                    trigger="manual"
                    defaultShow={true}
                    placement={"top"}
                    overlay={
                    <Popover className="errorMessageComponentPopover">
                        <Popover.Content className="first-upper" style={{minWidth: "180px"}}>
                        {this.props.value}
                        </Popover.Content>
                    </Popover>
                    }
                    >
                    <div style={{width: "100%", position: "absolute", height: '0px'}}></div>
                </OverlayTrigger>
                :
                <><div className="first-upper" style={{  bottom: "0"}}>{this.props.value}</div></>
            }
            </div>
            }
            </>
        );
    }
}

export { ErrorMessage };