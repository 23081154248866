import { observable, makeObservable , action, computed ,toJS} from "mobx";
import axios from 'axios';
import globalStore from "./GlobalStore";

class VirtualTerminalStore {

    constructor() {
        makeObservable(this)
    }

    @observable
    entryPoint = '';
    
    @observable
    config = {};

    @observable
    customerData = {};

    @observable
    paymentDetails = {};

    @observable
    paymentMethod = 'ach';

    @observable
    token = '';

    @observable
    savedPaymentMethodSelected = '';
   
    @observable
    defaultPaymentMethodActiveKey = "1";
    
    @observable
    paymentMethodSavedPosition = "2";

    @observable
    disableSearchCustomer = false;
    
    @observable
    forceCustomerCreation = true;
    
    @observable
    temporaryToken = true;

    @observable
    credentials = [];

    @observable
    customers = [];
   
    @observable
    lineItems = [];

    @observable
    customerSelected = {};
   
    @observable
    lineItemSelected = {};

    @observable
    paymentPageErrorsMessages = {};

    @observable
    operation = "sale";

    @observable
    paymentPageErrors = {
        autopayStartDateError: false,
        autopayFrequencyError: false,
        autopayFinishError: false,
        
        paymentMethodsAchAccountHolderNameError: false,
        paymentMethodsAchAccountTypeError: false,
        paymentMethodsAchRoutingError: false,
        paymentMethodsAchAccountError: false,
        
        paymentMethodsCardNumberError: false,
        paymentMethodsCardExpirationDateError: false,
        paymentMethodsCardCvvError: false,
        paymentMethodsCardZipcodeError: false,
        paymentMethodsCardHolderNameError: false,

        credentialsMaxMinTicketError: false,

        customerError: false
    }

    @observable
    paymentPage= {
        amount:{
            categories: [{
                name: "amountDefaultPayabliApp",
                type: 'customer',
                label: 'Amount you wish to charge',
                value: '0.00',
                description: '',
                quantity: 1,
                showDescription: true
            }]
        },
        autopay:{
            finish: {
                untilCancelled: true,
                calendar: false,
            },
            frequency: {
                onetime: true,
                weekly: true,
                every2Weeks: true,
                every6Months: true,
                monthly: true,
                every3Months: true,
                annually: true
            },
            startDate:'',
            enabled: false,
            frequencySelected:'',
            finishSelected:''
        },
        paymentMethods:{
            methods: {
                visa: true,
                mastercard: true,
                discover: true,
                amex: true,
                diners: true,
                jcb: true,
                eCheck: true,
                applePay: true,
                googlePay: true,
                payPal: true,
                bitPay: true,
                checking : true,
                savings: true
            },
            savePaymentMethodForFuture: true,
            cardHolderName: ''
        },
        payor: {
            invoiceNumber: 
            {
                label: 'Invoice #',
                validation: 'text',
                value: ''
            }
            
        },
        notes:{
            placeholder: 'Please enter notes',
            value: ''
        }
    }

    @observable
    achInputs= {
        achAccountHolderName: {
            label: "Account Holder Name",
            placeholder: "Account Holder Name",
            floating: true,
            size: 6,
			value: '',
            row: 0,
            order: 0
        },
        achAccountType: {
            label: "Account Type",
            placeholder:"Select...",
            floating: true,
            size: 6,
			value: '',
            row: 0,
            order: 1
        },
        achRouting: {
            label: "Routing #",
            placeholder: "Routing #",
            floating: true,
            size: 6,
			value: '',
            row: 1,
            order: 0
        },
        achAccount: {
            label: "Account #",
            placeholder: "Account #",
            floating: true,
            size: 6,
			value: '',
            row: 1,
            order: 1
        }
    }

    @observable
    achRows = [["achAccountHolderName", "achAccountType"], ["achRouting", "achAccount"],  [] ]

    @observable
    cardInputs = {
            cardHolderName: {
            label: "Cardholder Name",
            placeholder: "Cardholder Name",
            floating: true,
            size: 6,
			value: '',
            row: 0,
            order: 0
        },
            cardNumber: {
                label: "Card Number",
                placeholder: "Card Number",
                floating: true,
            size: 6,
			value: '',
            row: 0,
            order: 1
        },
            cardExpirationDate: {
                label: "MM/YY",
                placeholder: "MM/YY",
                floating: true,
            size: 4,
			value: '',
            row: 1,
            order: 0
        },
            cardCvv: {
                label: "CVV",
                placeholder: "CVV",
                floating: true,
                size: 4,
			value: '',
                row: 1,
                order: 0
            },
            cardZipcode: {
                label: "Zip code",
                placeholder: "Zip code",
                floating: true,
            size: 4,
			value: '',
            row: 1,
            order: 2
        }
    }

    @observable
    cardRows = [["cardHolderName", "cardNumber"], ["cardExpirationDate", "cardCvv","cardZipcode"], []]

    @observable
    paymentPageTemplate= {
        amount:{
            categories: [{
                name: "amountDefaultPayabliApp",
                type: 'customer',
                label: 'Amount you wish to charge',
                value: '0.00',
                description: '',
                quantity: 1,
                showDescription: true
            }]
        },
        autopay:{
            finish: {
                untilCancelled: true,
                calendar: false,
            },
            frequency: {
                onetime: true,
                weekly: true,
                every2Weeks: true,
                every6Months: true,
                monthly: true,
                every3Months: true,
                annually: true
            },
            startDate:'',
            enabled: false,
            frequencySelected:'',
            finishSelected:''
        },
        paymentMethods:{
            methods: {
                visa: true,
                mastercard: true,
                discover: true,
                amex: true,
                eCheck: true,
                applePay: true,
                googlePay: true,
                payPal: true,
                bitPay: true,
                checking : true,
                savings: true
            },
            savePaymentMethodForFuture: true,
            cardHolderName: ''
        },
        payor: {
            invoiceNumber: 
            {
                label: 'Invoice #',
                validation: 'numbers',
                value: ''
            }
            
        },
        notes:{
            placeholder: 'Please enter notes',
            value: ''
        }
    }
    

    @action
    clearACHForm(){
        this.paymentPage.paymentMethods.achAccountHolderName = '';
        this.paymentPage.paymentMethods.achAccountType = '';
        this.paymentPage.paymentMethods.achRouting = '';
        this.paymentPage.paymentMethods.reAchRouting = '';
        this.paymentPage.paymentMethods.achAccount = '';
        this.paymentPage.paymentMethods.reAchAccount = '';
    }

    @action
    clearCCForm(){
        this.paymentPage.paymentMethods.cardNumber = '';
        this.paymentPage.paymentMethods.cardExpirationDate = '';
        this.paymentPage.paymentMethods.cardCvv = '';
        this.paymentPage.paymentMethods.cardZipcode = '';
        this.paymentPage.paymentMethods.cardHolderName = '';
    }

    getFieldOrder(items){
        var max = 0;
        items.forEach(function (item, index) {
            if(parseInt(item.order) > parseInt(max)){
                max = parseInt(item.order);
            }
        });
        return max + 1;
    }

    @action
    removeAmountCategory(event){
        let idRemove = (event.target.id).replace("categoryRemove", "");
        this.paymentPage.amount.categories.splice(idRemove, 1);
    }

    @action
    setAchArray(ar) {
        this.achRows=ar;
    }

    @action
    setCardArray(ar) {
        this.cardRows = ar;
    }

    @action
    setPaymentMethod(method) {
        this.paymentMethod = method;
    }
   
    @action
    setDefaultPaymentMethodActiveKey(key) {
        this.defaultPaymentMethodActiveKey = key;
    }
    
    @action
    setSavedPaymentMethodSelected(data) {
        this.savedPaymentMethodSelected = data;
    }
    
    @action
    savePaymentMethodForFuture(value) {
        this.paymentPage.paymentMethods.savePaymentMethodForFuture = value;
    }

    @action
    handleCategoryQtySubstract(event){
        var category = event.target.id.replace("amountCategoryQtySubstract", "");
        if(this.paymentPage.amount.categories[category].quantity > 1)
            this.paymentPage.amount.categories[category].quantity--;
    }

    @action
    handleCategoryQtyAdd(event){
        var category = event.target.id.replace("amountCategoryQtyAdd", "");
        this.paymentPage.amount.categories[category].quantity++;
    }

    @action
    handlePayorTextChange(field, value) {
        this.paymentPage.payor[field].value = value;
    }

    @action
    handleNotesTextChange(value) {
        this.paymentPage.notes.value= value;
    }

    @action
    addNewLineItemField(item){
        return axios.post(process.env.REACT_APP_URL_API+ 'LineItem/' + this.entryPoint,{
            "itemProductName": item.label,
            "itemDescription": item.description,
            "itemCost": Number(parseFloat(item.value).toFixed(2)),
            "itemQty": 1
        },
        {
            headers: {'requestToken': this.token}
        })
        .then(res => {
            if(this.paymentPage.amount.categories.length === 1 && this.paymentPage.amount.categories[0].value === '0.00'){
                this.cleanCategories();
            }
            this.paymentPage.amount.categories.push(item);
   
            if(res.data.pageidentifier){
                this.setToken(res.data.pageidentifier);
            }
        })
        .catch(error => {
            throw error;
        });
    }

    @action
    cleanCategories(){
        this.paymentPage.amount.categories = [];
    }
    
    @action
    setCategories(categories){
        this.paymentPage.amount.categories = categories;
    }
    

    @action
    setConfig(config){
        this.config = config;
    }

    @action
    handleCategoryTextChangeMask(field, key ,value) {
        this.paymentPage.amount.categories[field][key]= value;
    }

    @action
    disableAutopay(checked){
        this.paymentPage.autopay.enabled = checked;
    }
    
    @action
    handleTextChangeACH(field, value) {
        this.paymentPage.paymentMethods[field]= value;
    }

    @computed
    get amountCategories(){
        return this.paymentPage.amount.categories; 
    }

    @action
    setAmountCategories(categories){
        this.paymentPage.amount.categories = categories; 
    }
    
    @action
    setPaymentMethodSavedPosition(data){
        this.paymentMethodSavedPosition = data; 
    }

    @action
    setPaymentMethodSaved(paymentMethodSaved){
        this.paymentPage.paymentMethods.paymentMethodSaved = paymentMethodSaved; 
    }

    @computed
    get isAutopay(){
        return this.paymentPage.autopay.enabled;
    }

    @computed
    get hasCards(){
        return this.paymentPage.paymentMethods.methods.visa || 
        this.paymentPage.paymentMethods.methods.mastercard || 
        this.paymentPage.paymentMethods.methods.discover || 
        this.paymentPage.paymentMethods.methods.amex ||
        this.paymentPage.paymentMethods.methods.diners || 
        this.paymentPage.paymentMethods.methods.jcb
        ;
    }

    @computed
    get hasCCError(){
        return this.paymentPageErrors.paymentMethodsCardNumberError ||
        this.paymentPageErrors.paymentMethodsCardExpirationDateError ||
        this.paymentPageErrors.paymentMethodsCardCvvError ||
        this.paymentPageErrors.paymentMethodsCardZipcodeError;

    }

    @computed
    get hasECheck(){
        return this.paymentPage.paymentMethods.methods.eCheck && (this.paymentPage.paymentMethods.methods.checking || this.paymentPage.paymentMethods.methods.savings)
    }

    @computed
    get getPaymentPageErrors(){
        return this.paymentPageErrors;
    }

    @action
    setOperation(value) {
        this.operation = value;
    }

    @computed
    get getpaymentPageErrorsMessages(){
        return this.paymentPageErrorsMessages;
    }

    @action
    setPaymentPageError(field, value){
        this.paymentPageErrors[field] = value;
    }
    
    @action
    setPaymentPageErrorMessage(field, value){
        this.paymentPageErrorsMessages[field] = value;
    }
    
    @action
    resetPaymentPage(){
        this.paymentPage = this.paymentPageTemplate;
        this.savedPaymentMethodSelected = '';
        this.lineItemSelected = {};
    }

    @action
    setPaymentMethodStatus(key,status){
        this.paymentPage.paymentMethods.methods[key] = status;
    }

    @action
    clearPaymentPageError(){
        this.paymentPageErrors = {
            autopayStartDateError: false,
            autopayFrequencyError: false,
            autopayFinishError: false,
            
            paymentMethodsAchAccountHolderNameError: false,
            paymentMethodsAchAccountTypeError: false,
            paymentMethodsAchRoutingError: false,
            paymentMethodsAchAccountError: false,
            
            paymentMethodsCardNumberError: false,
            paymentMethodsCardExpirationDateError: false,
            paymentMethodsCardCvvError: false,
            paymentMethodsCardZipcodeError: false,
            paymentMethodsCardHolderNameError: false,

            credentialsMaxMinTicketError: false,

            customerError: false
        }
    }

    @action
    hasPaymentPageErrors(){
        var paymentPageErrors = Object.entries(toJS(this.paymentPageErrors));
        var errors = [];
       
        paymentPageErrors.forEach(function (item, index){
            if(item[1]===true){
                errors.push(item[0]);
            }
        });

        return errors.length > 0 ? true : false;
    }

    @action
    getPageErrors() {
        let paymentPageErrors = Object.entries(toJS(this.paymentPageErrors));
        let errors = [];
        let storeObj = this;

        paymentPageErrors.forEach(function (item, index) {
            if (item[1] === true) {
                //errors.push(item[0]);
                switch(item[0]){
                    case "paymentMethodsCardHolderNameError":
                        errors.push({ code: 801, key: "paymentMethodsCardHolderNameError", text: storeObj.getpaymentPageErrorsMessages.cardHolderName })
                        break;
                    case "paymentMethodsCardNumberError":
                        errors.push({ code: 802, key: "paymentMethodsCardNumberError", text: storeObj.getpaymentPageErrorsMessages.cardNumber })
                        break;
                    case "paymentMethodsCardExpirationDateError":
                        errors.push({ code: 803, key: "paymentMethodsCardExpirationDateError", text: storeObj.getpaymentPageErrorsMessages.cardExpirationDate })
                        break;
                    case "paymentMethodsCardCvvError":
                        errors.push({ code: 804, key: "paymentMethodsCardCvvError", text: storeObj.getpaymentPageErrorsMessages.cardCvv })
                        break;
                    case "paymentMethodsCardZipcodeError":
                        errors.push({ code: 805, key: "paymentMethodsCardZipcodeError", text: storeObj.getpaymentPageErrorsMessages.cardZipcode })
                        break;
                    case "paymentMethodsAchAccountHolderNameError":
                        errors.push({ code: 901, key: "paymentMethodsAchAccountHolderNameError", text: storeObj.getpaymentPageErrorsMessages.achAccountHolderName })
                        break;
                    case "paymentMethodsAchAccountTypeError":
                        errors.push({ code: 902, key: "paymentMethodsAchAccountTypeError", text: storeObj.getpaymentPageErrorsMessages.achAccountType })
                        break;
                    case "paymentMethodsAchRoutingError":
                        errors.push({ code: 903, key: "paymentMethodsAchRoutingError", text: storeObj.getpaymentPageErrorsMessages.achRouting })
                        break;
                    case "paymentMethodsAchAccountError":
                        errors.push({ code: 904, key: "paymentMethodsAchAccountError", text: storeObj.getpaymentPageErrorsMessages.achAccount })
                        break;
                    case "rePaymentMethodsAchRoutingError":
                        errors.push({ code: 905, key: "rePaymentMethodsAchRoutingError", text: storeObj.getpaymentPageErrorsMessages.reAchRouting })
                    case "rePaymentMethodsAchAccountError":
                        errors.push({ code: 906, key: "rePaymentMethodsAchAccountError", text: storeObj.getpaymentPageErrorsMessages.reAchAccount })
                        break;
                    default:
                        break;
                }
            }
        });
        return errors;
    }

    getCCTypeValidationsErrorCases(){
        let paymentPage = this.paymentPage;
        let validators = globalStore.validators;
        let ccType = globalStore.creditCardType(paymentPage.paymentMethods.cardNumber);
        
        let ccTypeAux = ccType;
        if (ccType === "american-express") {
            ccTypeAux = "amex";
        }
        if (ccType === "diners-club") {
            ccTypeAux = "diners";
        }

        if(ccTypeAux === "amex"){
            return !validators.isLength(15, paymentPage.paymentMethods.cardNumber);
        } else if(ccTypeAux === "diners"){
            return !(validators.isLength(16, paymentPage.paymentMethods.cardNumber) || validators.isLength(14, paymentPage.paymentMethods.cardNumber));
        }else{
            return !validators.isLength(16, paymentPage.paymentMethods.cardNumber);
        }
    }

    @action
    validateFieldsByMethod(method, globalConfig) {

        let paymentPage = this.paymentPage;
        let validators = globalStore.validators;
        let ern = 0;
        // validating ach form
        if (method === "ach") {
            if (validators.isEmpty(paymentPage.paymentMethods.achAccountHolderName) || validators.isMaxLength(250, paymentPage.paymentMethods.achAccountHolderName)) {
                ern++;
            }
            if (validators.isEmpty(paymentPage.paymentMethods.achAccountType) || validators.isMaxLength(250, paymentPage.paymentMethods.achAccountType)) {
                ern++;
            }
            if (validators.isEmpty(paymentPage.paymentMethods.achRouting) || validators.isMaxLength(9, paymentPage.paymentMethods.achRouting) || validators.stringValidator('routing', paymentPage.paymentMethods.achRouting)) {
                ern++;
            }
            if (validators.isEmpty(paymentPage.paymentMethods.achAccount) || validators.stringValidator('numbers', paymentPage.paymentMethods.achAccount) || validators.isMinLength(4, paymentPage.paymentMethods.achAccount) || validators.isMaxLength(17, paymentPage.paymentMethods.achAccount)) {
                ern++;
            }

            if((globalConfig && globalConfig.ach && 
                globalConfig.ach.inputs && globalConfig.ach.inputs.achRouting && 
                globalConfig.ach.inputs.achRouting.confirm === true) &&
                (validators.isEmpty(paymentPage.paymentMethods.reAchRouting) ||
                paymentPage.paymentMethods.reAchRouting !== paymentPage.paymentMethods.achRouting)
            ){
                ern++;
            }

            if((globalConfig && globalConfig.ach && 
                globalConfig.ach.inputs && globalConfig.ach.inputs.achAccount && 
                globalConfig.ach.inputs.achAccount.confirm === true) &&
                (validators.isEmpty(paymentPage.paymentMethods.reAchAccount) ||
                paymentPage.paymentMethods.reAchAccount !== paymentPage.paymentMethods.achAccount)
            ){
                ern++;
            }
            

        } else if (method === "card") {
            let ccType = globalStore.creditCardType(paymentPage.paymentMethods.cardNumber);
            let ccTypeAux = ccType;
            if (ccType === "american-express") {
                ccTypeAux = "amex";
            }
            if (ccType === "diners-club") {
                ccTypeAux = "diners";
            }

            if(ccTypeAux === "unknown" || validators.isEmpty(paymentPage.paymentMethods.cardNumber) || this.getCCTypeValidationsErrorCases() || validators.stringValidator('card', paymentPage.paymentMethods.cardNumber) || paymentPage.paymentMethods.methods[ccTypeAux] === false){
                ern++;
            }
            if (validators.isEmpty(paymentPage.paymentMethods.cardExpirationDate) || validators.stringValidator('exp', paymentPage.paymentMethods.cardExpirationDate)) {
                ern++;
            }
            else {

                let expDateYear = paymentPage.paymentMethods.cardExpirationDate.substr(-2);
                let expDateMonth = paymentPage.paymentMethods.cardExpirationDate.substr(0, 2);
                let currentYear = new Date().getFullYear().toString().substr(-2);
                let currentMonth = new Date().getMonth().toString();

                if (parseInt(expDateYear) < parseInt(currentYear)) {
                    ern++;
                }
                if (((parseInt(expDateYear) === parseInt(currentYear)) && (parseInt(expDateMonth) <= parseInt(currentMonth) + 1) || (parseInt(expDateMonth) > 12))) {
                    ern++;
                }

            }

            if(globalConfig && globalConfig.card && globalConfig.card.inputs && globalConfig.card.inputs.cardCvv && globalConfig.card.inputs.cardCvv.hidden !== true){
                if (validators.isEmpty(paymentPage.paymentMethods.cardCvv) || (ccType === "american-express" ? validators.stringValidator('cvvamex', paymentPage.paymentMethods.cardCvv) : validators.stringValidator('cvv', paymentPage.paymentMethods.cardCvv))) {
                    ern++;
                }
            }

            if(globalConfig && globalConfig.card && globalConfig.card.inputs && globalConfig.card.inputs.cardZipcode && globalConfig.card.inputs.cardZipcode.hidden !== true){
                

                let zipCodeValidator = validators.stringValidator('zipcode', paymentPage.paymentMethods.cardZipcode);
                if(globalConfig && globalConfig.card && globalConfig.card.inputs && globalConfig.card.inputs.cardZipcode && globalConfig.card.inputs.cardZipcode.country && Array.isArray(globalConfig.card.inputs.cardZipcode.country)){
                    zipCodeValidator = !globalStore.checkZipFormat(globalConfig.card.inputs.cardZipcode.country, paymentPage.paymentMethods.cardZipcode)
                }

                if (validators.isEmpty(paymentPage.paymentMethods.cardZipcode) || zipCodeValidator) {
                    ern++;
                }
            }

            if(globalConfig && globalConfig.card && globalConfig.card.inputs && globalConfig.card.inputs.cardHolderName && globalConfig.card.inputs.cardHolderName.hidden !== true){
                if(globalConfig?.card?.inputs?.cardHolderName?.required === true){
                    if (validators.isEmpty(paymentPage.paymentMethods.cardHolderName) || validators.stringValidator('alpha', paymentPage.paymentMethods.cardHolderName)) {
                        ern++;
                    }
                }else{
                    if (validators.stringValidator('alpha', paymentPage.paymentMethods.cardHolderName)) {
                        ern++;
                    }
                }
            }

            
        }

        return (ern > 0) ? true : false;
    }

    searchingCustomer(value){
        return axios.get(process.env.REACT_APP_URL_API+ 'Query/customers/' + this.entryPoint ,{
        headers: {
            'requestToken': this.token,
        },
        params: {
            "limitRecord": "10",
            "name(ct)": value,
            "includeExtendedData": true
            }
        })
        .then(res => {
            this.clearCustomer();
            this.clearCustomerSelected();
            let records = res.data.Records;
            let store = this;
            records.forEach(function (item, index) {
                store.addCustomer(item)
            });
            if(res.data.Summary.pageidentifier){
                this.setToken(res.data.Summary.pageidentifier);
            }
        })
        .catch(error => {
            this.clearCustomerSelected();
            this.clearCustomer();
            throw error;
        });
    }

    searchingLineItems(value){
        
        return axios.get(process.env.REACT_APP_URL_API+ 'Query/lineitems/' + this.entryPoint ,{
        headers: {
            'requestToken': this.token,
        },
        params: {
            "limitRecord": "10",
            "name(ct)": value
            }
        })
        .then(res => {
            this.clearLineItems();
            this.clearLineItemSelected();
            let records = res.data.Records;
            let store = this;
            records.forEach(function (item, index) {
                store.addLineItem(item);
            });
            if(res.data.Summary.pageidentifier){
                this.setToken(res.data.Summary.pageidentifier);
            }
        })
        .catch(error => {
            this.clearLineItems();
            this.clearLineItemSelected();
            throw error;
        });
    }

    @action
    selectCustomer(position){
        //let customer = this.customers[position];
        //delete customer.StoredMethods;
        //delete customer.Subscriptions;
        this.customerSelected = this.customers[position];
    }

    @action
    selectCustomerObject(customer){
        //delete customer.StoredMethods;
        //delete customer.Subscriptions;
        this.customerSelected = customer;
        this.disableSearchCustomer = true;
    }

    @action
    updateCustomerPaymentsOptions(){
        
        let credentialsPermissions = this.getCredentialsPermissions;
        
        if((this.getCustomerSelected.StoredMethods && this.getCustomerSelected.StoredMethods.length === 0) || (this.getCustomerSelected.StoredMethods === null)){
            if(this.hasCards && ((credentialsPermissions.card.onetime && !this.isAutopay) || (credentialsPermissions.card.recurring && this.isAutopay))){
                this.setPaymentMethod('card');
                this.setDefaultPaymentMethodActiveKey("0");
            }else if(this.hasECheck && ((credentialsPermissions.ach.onetime && !this.isAutopay) || (credentialsPermissions.ach.recurring && this.isAutopay))){
                this.setPaymentMethod('ach');
                this.setDefaultPaymentMethodActiveKey("1");
            }
            
        }
        else{
            this.setPaymentMethod('savedcard');
            this.setDefaultPaymentMethodActiveKey("2");
        }
    }

    @action
    handleFirstCategoryTextChange(value){
        this.paymentPage.amount.categories[0].description = value;
    }

    @action
    selectCustomerObjectInSearch(customer){
        this.customerSelected = customer;
    }
    
    @action
    selectLineItem(position){
        let item = toJS(this.lineItems[position]);

        let category = {
            name: item.itemProductName,
            type: "quantity",
            label: item.itemProductName,
            value: parseFloat(item.itemCost).toFixed(2),
            description: item.itemDescription,
            quantity: item.itemQty,
            showDescription: true
        };
        
        if(this.paymentPage.amount.categories.length === 1 && ( parseFloat(this.paymentPage.amount.categories[0].value) === 0 || isNaN(parseFloat(this.paymentPage.amount.categories[0].value)))){
            this.cleanCategories();
        }

        this.paymentPage.amount.categories.push(category);
    }

    @action
    clearCustomer(){
        this.customers = [];
    }
    
    @action
    clearLineItems(){
        this.lineItems = [];
    }
    
    @action
    clearCustomerSelected(){
        this.customerSelected = {};
    }

    @computed
    get getCustomerSelectedForPay(){
        if(this.customerSelected && this.customerSelected.customerId){
            return {customerId: this.customerSelected.customerId};
        }
        else{
            return {};
        }
       
    }
    
    @action
    clearLineItemSelected(){
        this.lineItemSelected = {};
    }

    @action
    addCustomer(customer){
        this.customers.push(customer);
    }
    
    @action
    addLineItem(item){
        delete item.Id;
        delete item.lastUpdated;
        delete item.createdAt;
        this.lineItems.push(item);
    }

    initUI(res, config){
        if(res.data && res.data.ach && res.data.card && res.data.identifier){
            this.setPaymentMethodStatus('eCheck', typeof config.ach.enabled === 'undefined' ? res.data.ach.enabled : config.ach.enabled && res.data.ach.enabled );
            this.setPaymentMethodStatus('checking', typeof config.ach.checking === 'undefined' ? res.data.ach.checking : config.ach.checking && res.data.ach.checking );
            this.setPaymentMethodStatus('savings', typeof config.ach.savings === 'undefined' ? res.data.ach.savings : config.ach.savings && res.data.ach.savings);
            if(res.data.card.enabled === false || config.card.enabled === false){
                this.setPaymentMethodStatus('visa', false);
                this.setPaymentMethodStatus('mastercard', false);
                this.setPaymentMethodStatus('discover', false);
                this.setPaymentMethodStatus('amex', false);
                this.setDefaultPaymentMethodActiveKey("1");
            }else{
                this.setPaymentMethodStatus('visa', config.card.visa && res.data.card.visa);
                this.setPaymentMethodStatus('mastercard', config.card.mastercard && res.data.card.mastercard);
                this.setPaymentMethodStatus('discover', config.card.discover && res.data.card.discover);
                this.setPaymentMethodStatus('amex', config.card.amex && res.data.card.amex);
            }

            this.disableAutopay(false)
            if(config.oneTimePayment === false){
                this.disableAutopay(true)
            }

            if(config.lineItems){
                this.cleanCategories();
                this.setCategories(config.lineItems);
            }

            this.setToken(res.data.identifier);
            this.setEntryPoint(res.data.entryPoint);
        }
        else{
            throw new Error('The data format from server is incorrect.');
        }
    }

    @action
    getPaymentPageFromApi(){
        try{
            let config = this.config;
            if(config.defaultOpen){
                this.setDefaultPaymentMethodActiveKey(config.defaultOpen.toLowerCase() === 'card' ? "0" : "1");
            }
            else{
                this.setDefaultPaymentMethodActiveKey("0");
            }

            return axios.get(process.env.REACT_APP_URL_API+ 'Tools/init/terminal/'+ config.entryPoint,{
            headers: {
                'requestToken': config.token,
            }
            })
            .then(res => {
                this.setCredentials(res.data.credentials ? res.data.credentials: []);
                if(res.data.identifier){
                    this.setToken(res.data.identifier);
                }
                this.initUI(res, config);
            })
            .catch(error => {
                throw error;
            });
            
        }catch(error){
            throw error;
        }

    }

    @action
    getDevicesFromApi(){
        let config = this.config;

        return axios.get(process.env.REACT_APP_URL_API+ 'Cloud/list/' + config.entryPoint ,{
            headers: {
                'requestToken': config.token,
            }})
            .then(res => {
                return res.data;
            })
            .catch(error => {
                throw error;
            });
    }

    @action
    setCredentials(credentials){
        this.credentials = credentials;
    }

    @action
    handleAutopayStartDate(date) {
        this.paymentPage.autopay.startDate = date;
    }

    @action
    handleAutopayEndDate(value){
        this.paymentPage.autopay.finishSelected = value;
    }

    getAutopayEndDate(){
        return this.paymentPage.autopay.finishSelected;
    }

    @action
    handleAutopayFrequency(value){
        this.paymentPage.autopay.frequencySelected = value;
    }

    @action
    makePayment(operation, addPaymentMethod) {
        let executeOperation = operation ? operation : "sale";
        if(this.isAutopay)
        {
            if(this.getPaymentMethod.toLowerCase() === 'card' || this.getPaymentMethod.toLowerCase() === 'savedcard'){
                
                return axios.post(process.env.REACT_APP_URL_API+ 'Subscription/add' , this.paymentCardDataRecurring,
                {
                    headers: {'requestToken': this.token}
                })
                .then((response) => {
                    return response;
                })
                .catch((reason) => {
                    throw reason;
                });

            }else if(this.getPaymentMethod.toLowerCase() === 'ach' || this.getPaymentMethod.toLowerCase() === 'savedach'){

                return axios.post(process.env.REACT_APP_URL_API+ 'Subscription/add' , this.paymentACHDataRecurring,
                {
                    headers: {'requestToken': this.token}
                })
                .then((response) => {
                    return response;
                })
                .catch((reason) => {
                    throw reason;
                });

            }else if(this.getPaymentMethod.toLowerCase() === 'device'){

            }
        }
        else{
            if(this.getPaymentMethod.toLowerCase() === 'card' || this.getPaymentMethod.toLowerCase() === 'savedcard'){
                return axios.post(process.env.REACT_APP_URL_API+ (executeOperation === "sale" ? 'MoneyIn/getpaid?forceCustomerCreation='+this.forceCustomerCreation : 'MoneyIn/authorize') , this.paymentCardData,
                {
                    headers: {'requestToken': this.token}
                })
                .then((response) => {
                    if(response.data?.pageIdentifier){
                        this.setToken(response.data.pageIdentifier);
                    }

                    if(this.paymentPage.paymentMethods.savePaymentMethodForFuture && this.getPaymentMethod.toLowerCase() === 'card'){
                        if(addPaymentMethod === true){
                            this.addPaymentMethod(this.customerSelected.customerId, true);
                        }
                    }
                    
                    return response;
                })
                .catch((reason) => {
                    throw reason;
                });

            }else if(this.getPaymentMethod.toLowerCase() === 'ach' || this.getPaymentMethod.toLowerCase() === 'savedach'){

                return axios.post(process.env.REACT_APP_URL_API+ (executeOperation === "sale" ? 'MoneyIn/getpaid?forceCustomerCreation='+this.forceCustomerCreation : 'MoneyIn/authorize') , this.paymentACHData,
                {
                    headers: {'requestToken': this.token}
                })
                .then((response) => {
                    if(response.data?.pageIdentifier){
                        this.setToken(response.data.pageIdentifier);
                    }

                    if(this.paymentPage.paymentMethods.savePaymentMethodForFuture && this.getPaymentMethod.toLowerCase() === 'ach'){
                        if(addPaymentMethod === true){
                            this.addPaymentMethod(this.customerSelected.customerId, true);
                        }
                    }
                    
                    return response;
                })
                .catch((reason) => {
                    throw reason;
                });
            }else if(this.getPaymentMethod.toLowerCase() === 'device'){
                return axios.post(process.env.REACT_APP_URL_API+ (executeOperation === "sale" ? 'MoneyIn/getpaid' : 'MoneyIn/authorize') , this.deviceData,
                {
                    headers: {'requestToken': this.token},
                    timeout: 300000 // 5 minutes
                })
                .then((response) => {
                    if(response.data?.pageIdentifier){
                        this.setToken(response.data.pageIdentifier);
                    }
                    return response;
                })
                .catch((reason) => {
                    throw reason;
                });
            }
        }
        
    }

    sendReceipt(referenceId) {
        return axios.get(process.env.REACT_APP_URL_API + 'MoneyIn/sendreceipt/' + referenceId, {
            headers: {
                'requestToken': this.token,
            }
        })
            .then(res => {

            })
            .catch(error => {

                throw error;
            });
    }

    @computed
    get paymentCardDataRecurring(){
        let paymentMethod = null;
        if(this.getPaymentMethod.toLowerCase() === "savedcard"){
            paymentMethod = {
                method : "card",
                storedMethodId : this.paymentPage.paymentMethods.paymentMethodSaved ,
            };
        }
        else{
            paymentMethod = {
                method : "card",
                cardnumber : this.paymentPage.paymentMethods.cardNumber ,
                cardexp : this.paymentPage.paymentMethods.cardExpirationDate,
                cardcvv : this.paymentPage.paymentMethods.cardCvv,
                cardzip : this.paymentPage.paymentMethods.cardZipcode,
                cardHolder : this.paymentPage.paymentMethods.cardHolderName,
            };
        }
        
        let paymentData = {
            entryPoint: this.entryPoint,
            source : "Virtual Terminal Component",
            paymentMethod : paymentMethod,
            paymentDetails : {
                totalAmount : Number(parseFloat(this.totalAmount.totalAmount).toFixed(2)),
                serviceFee : Number(parseFloat(this.totalAmount.fee).toFixed(2)),
                categories : this.getCategoriesFieldsToPay()
            },
            customerData : this.getCustomerSelectedForPay,
            scheduleDetails: {
                startDate : this.dateStringFormat(this.paymentPage.autopay.startDate),
                endDate : this.getAutopayFinishString(),
                frequency : this.paymentPage.autopay.frequencySelected
            },
            invoiceData: {
                invoiceNumber : this.paymentPage.payor.invoiceNumber.value && this.paymentPage.payor.invoiceNumber.value !== '' ? this.paymentPage.payor.invoiceNumber.value : null
            },
            orderDescription : this.paymentPage.notes.value && this.paymentPage.notes.value !== '' ? this.paymentPage.notes.value : null
            
        }
        return paymentData;
    }

    @computed
    get paymentACHDataRecurring(){
        let paymentMethod = null;
        if(this.getPaymentMethod.toLowerCase() === "savedach"){
            paymentMethod = {
                method : "ach",
                storedMethodId : this.paymentPage.paymentMethods.paymentMethodSaved
            };
        }
        else{
            paymentMethod = {
                method : "ach",
                achRouting : this.paymentPage.paymentMethods.achRouting ,
                achAccount : this.paymentPage.paymentMethods.achAccount,
                achAccountType: this.paymentPage.paymentMethods.achAccountType,
                achHolder: this.paymentPage.paymentMethods.achAccountHolderName
            };
        }

        let paymentData = {
            entryPoint: this.entryPoint,
            //subdomain : this.getPaymentPageSubdomain,
            source : "Virtual Terminal Component",
            paymentMethod : paymentMethod,
            paymentDetails : {
                totalAmount : Number(parseFloat(this.totalAmount.totalAmount).toFixed(2)),
                serviceFee : Number(parseFloat(this.totalAmount.fee).toFixed(2)),
                categories : this.getCategoriesFieldsToPay()
            },
            customerData : this.getCustomerSelectedForPay,
            scheduleDetails: {
                startDate : this.dateStringFormat(this.paymentPage.autopay.startDate),
                endDate : this.getAutopayFinishString(),
                frequency : this.paymentPage.autopay.frequencySelected
            },
            invoiceData: {
                invoiceNumber : this.paymentPage.payor.invoiceNumber.value && this.paymentPage.payor.invoiceNumber.value !== '' ? this.paymentPage.payor.invoiceNumber.value : null
            },
            orderDescription : this.paymentPage.notes.value && this.paymentPage.notes.value !== '' ? this.paymentPage.notes.value : null
            
        }
        return paymentData;
    }

    getAutopayFinishString(){
        var date = this.paymentPage.autopay.finishSelected;
        if(typeof date === 'string' || date instanceof String){
            return date;
        }else{
            return this.dateStringFormat(date);
        }
    }
    
    dateStringFormat(date) {
        var year = date.getFullYear();
        var month = (1 + date.getMonth()).toString();
        month = month.length > 1 ? month : '0' + month;
        var day = date.getDate().toString();
        day = day.length > 1 ? day : '0' + day;
        return month + '/' + day + '/' + year;
    }

    @computed
    get paymentACHData(){
        let paymentMethod = null;
        if(this.getPaymentMethod.toLowerCase() === "savedach"){
            paymentMethod = {
                method : "ach",
                storedMethodId : this.paymentPage.paymentMethods.paymentMethodSaved ,
            };
        }else{
            paymentMethod = {
                method : "ach",
                achRouting : this.paymentPage.paymentMethods.achRouting ,
                achAccount : this.paymentPage.paymentMethods.achAccount,
                achAccountType: this.paymentPage.paymentMethods.achAccountType,
                achHolder: this.paymentPage.paymentMethods.achAccountHolderName
            };
        }

        let paymentData = {
            entryPoint: this.entryPoint,
            source : "Virtual Terminal Component",
            paymentMethod : paymentMethod,
            paymentDetails : {
                totalAmount : Number(parseFloat(this.totalAmount.totalAmount).toFixed(2)),
                serviceFee : Number(parseFloat(this.totalAmount.fee).toFixed(2)),
                categories : this.getCategoriesFieldsToPay()
            },
            customerData : this.getCustomerSelectedForPay,
            invoiceData: {
                invoiceNumber : this.paymentPage.payor.invoiceNumber.value && this.paymentPage.payor.invoiceNumber.value !== '' ? this.paymentPage.payor.invoiceNumber.value : null
            },
            orderDescription : this.paymentPage.notes.value && this.paymentPage.notes.value !== '' ? this.paymentPage.notes.value : null
            
        }
        return paymentData;
    }

    @computed
    get paymentCardData(){
        let paymentMethod = null;
        if(this.getPaymentMethod.toLowerCase() === "savedcard"){
            paymentMethod = {
                method : "card",
                storedMethodId : this.paymentPage.paymentMethods.paymentMethodSaved ,
            };
        }else{
            paymentMethod = {
                method : "card",
                cardnumber : this.paymentPage.paymentMethods.cardNumber ,
                cardexp : this.paymentPage.paymentMethods.cardExpirationDate,
                cardcvv : this.paymentPage.paymentMethods.cardCvv,
                cardzip : this.paymentPage.paymentMethods.cardZipcode,
                cardHolder : this.paymentPage.paymentMethods.cardHolderName,
            };
        }
        
        let paymentData = {
            entryPoint: this.entryPoint,
            source : "Virtual Terminal Component",
            paymentMethod : paymentMethod,
            paymentDetails : {
                totalAmount : Number(parseFloat(this.totalAmount.totalAmount).toFixed(2)),
                serviceFee : Number(parseFloat(this.totalAmount.fee).toFixed(2)),
                categories : this.getCategoriesFieldsToPay()
            },
            customerData : this.getCustomerSelectedForPay,
            invoiceData: {
                invoiceNumber : this.paymentPage.payor.invoiceNumber.value && this.paymentPage.payor.invoiceNumber.value !== '' ? this.paymentPage.payor.invoiceNumber.value : null
            },
            orderDescription : this.paymentPage.notes.value && this.paymentPage.notes.value !== '' ? this.paymentPage.notes.value : null
            
        }
        return paymentData;
    }

    @computed
    get deviceData(){
        let paymentMethod = null;
        paymentMethod = {
            method : "cloud",
            device : this.paymentPage.paymentMethods.device
        };
        
        let paymentData = {
            entryPoint: this.entryPoint,
            source : "Virtual Terminal Component Device",
            paymentMethod : paymentMethod,
            paymentDetails : {
                totalAmount : Number(parseFloat(this.totalAmount.totalAmount).toFixed(2)),
                serviceFee : Number(parseFloat(this.totalAmount.fee).toFixed(2)),
                categories : this.getCategoriesFieldsToPay()
            },
            customerData : this.getCustomerSelectedForPay,
            invoiceData: {
                invoiceNumber : this.paymentPage.payor.invoiceNumber.value && this.paymentPage.payor.invoiceNumber.value !== '' ? this.paymentPage.payor.invoiceNumber.value : null
            },
            orderDescription : this.paymentPage.notes.value && this.paymentPage.notes.value !== '' ? this.paymentPage.notes.value : null
        }
        return paymentData;
    }

    getCategoriesFieldsToPay(){
        var categoryFields = Object.entries(toJS(this.amountCategories));
        var categoryFieldsReturn = [];
        categoryFields.forEach(function (item, index) {
            categoryFieldsReturn.push({
                'amount': parseFloat(item[1].value),
                'label': item[1].label,
                'qty': parseInt(item[1].quantity)
            });
        });
        return categoryFieldsReturn;
    }

    @computed
    get getCustomerSelected(){
        return this.customerSelected;
    }

    @computed
    get getSelectedCustomerString(){
        return (this.customerSelected.Firstname ? this.customerSelected.Firstname : this.customerSelected.firstname ? this.customerSelected.firstname : "Firstname" ) + " " + (this.customerSelected.Lastname ? this.customerSelected.Lastname : this.customerSelected.lastname ? this.customerSelected.lastname : "Lastname") + " - " + (this.customerSelected.Email ? this.customerSelected.Email : this.customerSelected.email ? this.customerSelected.email : "Email") ;
    }

    @computed
    get hasCustomerSelected(){
        if(Object.keys(this.customerSelected).length === 0){
            return false;
        }else{
            return true;
        }
    }

    @computed
    get getCredentialsPermissions(){
        let credentials = Object.entries(toJS(this.credentials));
        let availableCredentials = {
            card : {onetime: false, recurring: false},
            ach : {onetime: false, recurring: false},
            wallet : {onetime: false, recurring: false},
            cloud : {onetime: false, recurring: false}
        }
        credentials.forEach(function (item, index) {
            switch(item[1].service.toLowerCase()){
                case "card":
                    if(item[1].mode === 1){
                        availableCredentials.card.recurring = true;
                    }
                    else{
                        availableCredentials.card.onetime = true;
                    }
                    break;
                case "ach":
                    if(item[1].mode === 1){
                        availableCredentials.ach.recurring = true;
                    }
                    else{
                        availableCredentials.ach.onetime = true;
                    }
                    break;
                case "wallet":
                    if(item[1].mode === 1){
                        availableCredentials.wallet.recurring = true;
                    }
                    else{
                        availableCredentials.wallet.onetime = true;
                    }
                    break;
                case "cloud":
                    if(item[1].mode === 1){
                        availableCredentials.cloud.recurring = true;
                    }
                    else{
                        availableCredentials.cloud.onetime = true;
                    }
                    break;
                default:
                    break;
            }
            
        });
        return availableCredentials;
    }

    @action
    validateCredentialsMaxMinTicketError(){
        var categories = Object.entries(toJS(this.paymentPage.amount.categories));
        let credentials = Object.entries(toJS(this.credentials));
        let paymentMethod = this.getPaymentMethod;
        let netAmount = 0;
        let fee = 0;
        let objStore = this;

        categories.forEach(function (item, index) {
            if(item[1].optionalPay === true && !item[1].optionalPayChecked)
            {
                return;
            }
            netAmount = netAmount + (parseFloat(item[1].value) * parseFloat(item[1].quantity) );
        });

        credentials.forEach(function (item, index) {
            let isAutopayVar = objStore.isAutopay ? 1 : 0;
            if((paymentMethod.toLowerCase() === "card" || paymentMethod.toLowerCase() === "savedcard") && item[1].service.toLowerCase() === 'card' && item[1].mode === isAutopayVar){
                fee = item[1].cfeeFix + (netAmount*parseFloat(item[1].cfeeFloat)/100);
                if((fee + netAmount) > item[1].maxticket || (fee + netAmount) < item[1].minticket){
                    objStore.setPaymentPageError('credentialsMaxMinTicketError', true)
                }
                else{
                    objStore.setPaymentPageError('credentialsMaxMinTicketError', false)
                }

                return;
            }

            if( (paymentMethod.toLowerCase() === "ach" || paymentMethod.toLowerCase() === "savedach") && item[1].service.toLowerCase() === 'ach' && item[1].mode === isAutopayVar){
                fee = item[1].cfeeFix + (netAmount*parseFloat(item[1].cfeeFloat)/100);

                if((fee + netAmount) > item[1].maxticket || (fee + netAmount) < item[1].minticket){
                    objStore.setPaymentPageError('credentialsMaxMinTicketError', true)
                }
                else{
                    objStore.setPaymentPageError('credentialsMaxMinTicketError', false)
                }

                return;
            }
            
        });
    }

    getRoundUpDecimal2(pNumber){
        let numberFloat = (parseFloat(pNumber));
        return Math.ceil(numberFloat * 100) / 100;
	}

    @computed
    get totalAmount(){
        var categories = Object.entries(toJS(this.paymentPage.amount.categories));
        var credentials = Object.entries(toJS(this.credentials));
        var paymentMethod = this.getPaymentMethod;
        var netAmount = 0;
        var fee = 0;
        var objStore = this;

        categories.forEach(function (item, index) {
            if(item[1].optionalPay === true && !item[1].optionalPayChecked)
            {
                return;
            }
            netAmount = netAmount + (parseFloat(item[1].value) * parseFloat(item[1].quantity) );
        });

        //Calculating fee
        credentials.forEach(function (item, index) {
            let isAutopayVar = objStore.isAutopay ? 1 : 0;
            if((paymentMethod.toLowerCase() === "card" || paymentMethod.toLowerCase() === "savedcard") && item[1].service.toLowerCase() === 'card' && item[1].mode === isAutopayVar){
                fee = item[1].cfeeFix + objStore.getRoundUpDecimal2((netAmount*parseFloat(item[1].cfeeFloat)/100));
                if(item[1].cfeeMin !== 0 && fee < item[1].cfeeMin){
                    fee = item[1].cfeeMin;
                }
                if(item[1].cfeeMax !== 0 && fee > item[1].cfeeMax){
                    fee = item[1].cfeeMax;
                }
                return;
            }

            if( (paymentMethod.toLowerCase() === "ach" || paymentMethod.toLowerCase() === "savedach") && item[1].service.toLowerCase() === 'ach' && item[1].mode === isAutopayVar){
                fee = item[1].cfeeFix + objStore.getRoundUpDecimal2((netAmount*parseFloat(item[1].cfeeFloat)/100));
                if(item[1].cfeeMin !== 0 && fee < item[1].cfeeMin){
                    fee = item[1].cfeeMin;
                }
                if(item[1].cfeeMax !== 0 && fee > item[1].cfeeMax){
                    fee = item[1].cfeeMax;
                }
                return;
            }
            
        });
        
        netAmount = isNaN(netAmount) ? 0 : netAmount;
        fee = isNaN(fee) ? 0 : fee;

        var total = {
            netAmount: netAmount,
            fee: fee,
            totalAmount: netAmount + fee
        }
        return total;
    }

    @computed
    get getPaymentMethod(){
        return this.paymentMethod; 
    }

    @action
    setForceCustomerCreation(value){
        this.forceCustomerCreation = value;
    }
    
    @action
    setTemporaryToken(value){
        this.temporaryToken = value;
    }

    @action
    addPaymentMethod(customerId, notTemporaryToken){
        let paymentData = null;

        if(this.isAutopay){
            if(this.getPaymentMethod.toLowerCase() === 'card'){
                paymentData = this.paymentCardDataRecurring;
            }else if(this.getPaymentMethod.toLowerCase() === 'ach'){
                paymentData = this.paymentACHDataRecurring;
            }
        }else{
            if(this.getPaymentMethod.toLowerCase() === 'card'){
                paymentData = this.paymentCardData;
            }else if(this.getPaymentMethod.toLowerCase() === 'ach'){
                paymentData = this.paymentACHData;
            }
        }
        
        let payorData = {};
        if(customerId){
            payorData = {"customerId": parseInt(customerId)};
        }
        else {
            payorData = this.customerData;
        }

        let urlPost = process.env.REACT_APP_URL_API+ 'TokenStorage/add?temporary='+ this.temporaryToken + "&forceCustomerCreation="+this.forceCustomerCreation;

        if(notTemporaryToken === true){
            urlPost = process.env.REACT_APP_URL_API+ 'TokenStorage/add';
        }
    
        return axios.post(urlPost,{
            "paymentMethod": paymentData.paymentMethod,
            "customerData": payorData,
            "entryPoint": this.entryPoint
        },
        {
            headers: {'requestToken': this.token}
        })
        .then(res => {
            return res;
        })
        .catch(error => {
            throw error;
        });

    }


    @action
    makeEmbeddedPayment(auth) {
        let paymentData = null;
        if (this.isAutopay) {
            if (this.getPaymentMethod.toLowerCase() === 'card') {
                paymentData = this.paymentCardDataRecurring;
            } else if (this.getPaymentMethod.toLowerCase() === 'ach') {
                paymentData = this.paymentACHDataRecurring;
            }
        } else {
            if (this.getPaymentMethod.toLowerCase() === 'card') {
                paymentData = this.paymentCardData;
            } else if (this.getPaymentMethod.toLowerCase() === 'ach') {
                paymentData = this.paymentACHData;
            }
        }

        let payorData = this.customerData;
        let payDetails = this.paymentDetails;
        let moneyInPath = (auth === true ? "MoneyIn/authorize" : "MoneyIn/getpaid?forceCustomerCreation="+this.forceCustomerCreation);
        
        return axios.post(process.env.REACT_APP_URL_API + moneyInPath, {
            "paymentMethod": paymentData.paymentMethod,
            "customerData": payorData,
            "paymentDetails": payDetails,
            "source":'component',
            "entryPoint": this.entryPoint
        },
            {
                headers: { 'requestToken': this.token }
            })
            .then(response => {
                if(response.data?.pageIdentifier){
                    this.setToken(response.data.pageIdentifier);
                }
                return response;
            })
            .catch(error => {
                throw error;
            });

    }

    @action
    sendRawAPIRequest(entryapi, data) {
        return axios.post(process.env.REACT_APP_URL_API + entryapi, data,
            {
                headers: { 'requestToken': this.token }
            })
            .then(res => {
                return res;
            })
            .catch(error => {
                throw error;
            });
    }

    @action
    setToken(token){
        this.token = token;
    }
    
    @action
    setEntryPoint(entry){
        this.entryPoint = entry;
    }

    @action
    setCustomerData(entry) {
        this.customerData = entry;
    }

    @action
    setPaymentDetails(entry) {
        this.paymentDetails = entry;
    }
}

const virtualTerminalStore = new VirtualTerminalStore();
export default virtualTerminalStore;