import { observable, makeObservable , action, computed } from "mobx";
import axios from 'axios';
import { EncryptStorage } from 'encrypt-storage';
import VirtualTerminalStore from "./VirtualTerminalStore";

class CustomerStore {

    constructor() {
        makeObservable(this)
    }

    @observable
    statistics = null;

    @observable
    customer = null;
    
    @observable
    customerLastPayments = [];
    
    getCustomerFromApi(id){
        let encryptStorage = EncryptStorage(process.env.REACT_APP_SECRET_KEY);
        return axios.get(process.env.REACT_APP_URL_API+ 'Customer/' + id,{
            headers: {
              'requestToken': encryptStorage.getItem('pToken'),
            }
            })
            .then(res => {
                this.setCustomer(res.data);
                return res;
            })
            .catch(error => {
                throw error;
            });
    }

    getIdentifiers(pToken){
        return axios.get(process.env.REACT_APP_URL_API+ 'Tools/values/customerIdentifiers',{
            headers: {
              'requestToken': pToken,
            }
            })
            .then(res => {
                return res;
            })
            .catch(error => {
                throw error;
            });
    }

    @action
    getStatistics(idCustomer){
        let encryptStorage = EncryptStorage(process.env.REACT_APP_SECRET_KEY);
        return axios.get(process.env.REACT_APP_URL_API+ 'Statistic/customerbasic/m12/m/' + idCustomer,{
            headers: {
              'requestToken': encryptStorage.getItem('pToken'),
            }
            })
            .then(res => {
                this.setStatistics(res.data);
                return res;
            })
            .catch(error => {
                throw error;
            });
    }

    @action
    setStatistics(data){
        this.statistics = data;
    }

    updateCustomer(customer){
        let encryptStorage = EncryptStorage(process.env.REACT_APP_SECRET_KEY);
        let additionalFields = {};
        
        customer.additionalFields.forEach(function (item, index) {
            additionalFields[item.key] = item.value;
        });


        return axios.put(process.env.REACT_APP_URL_API+ 'Customer/' + customer.id,{
            "company": customer.company,
            "customerNumber": customer.customerNumber,
            "firstname": customer.firstname,
            "lastname": customer.lastname,
            "email": customer.email,
            "phone": customer.phone,
            
            "address": customer.address,
            "address1": customer.address1,
            "city": customer.city,
            "state":customer.state,
            "zip": customer.zip,
            "country": customer.country,

            "shippingAddress": customer.shippingAddress,
            "shippingAddress1": customer.shippingAddress1,
            "shippingCity": customer.shippingCity,
            "shippingState":customer.shippingState,
            "shippingZip": customer.shippingZip,
            "shippingCountry": customer.shippingCountry,
            
            "additionalFields": additionalFields,
            "identifierFields": customer.identifierFields
        },
        {
            headers: {'requestToken': encryptStorage.getItem('pToken')}
        })
        .then(res => {
            
        })
        .catch(error => {
            throw error;
        });
    }

    createCustomer(customer, config){
        let additionalFields = {};
        
        customer.additionalFields.forEach(function (item, index) {
            additionalFields[item.key] = item.value;
        });

        return axios.post(process.env.REACT_APP_URL_API+ 'Customer/single/' + config.entryPoint,{
            "company": customer.company,
            "customerNumber": customer.customerNumber,
            "firstname": customer.firstname,
            "lastname": customer.lastname,
            "email": customer.email,
            "phone": customer.phone,
            
            "address": customer.address,
            "address1": customer.address1,
            "city": customer.city,
            "state":customer.state,
            "zip": customer.zip,
            "country": customer.country,

            "shippingAddress": customer.shippingAddress,
            "shippingAddress1": customer.shippingAddress1,
            "shippingCity": customer.shippingCity,
            "shippingState":customer.shippingState,
            "shippingZip": customer.shippingZip,
            "shippingCountry": customer.shippingCountry,


            "additionalFields": additionalFields,
            "identifierFields": customer.identifierFields
        },
        {
            headers: {'requestToken': VirtualTerminalStore.token}
        })
        .then(res => {
            if(res.data.pageidentifier){
                VirtualTerminalStore.setToken(res.data.pageidentifier);
            }
            return res;
        })
        .catch(error => {
            throw error;
        });
    }

    @action
    getCustomerLastPayments(id,count){
        let limits = {limitRecord: count, fromRecord: 0};
        let filter = [];
        filter["payorCustomerId(eq)"] = id;
        let params = Object.assign({}, limits, filter);

        let encryptStorage = EncryptStorage(process.env.REACT_APP_SECRET_KEY);
        let entryPoint = encryptStorage.getItem('pEntry') ? encryptStorage.getItem('pEntry').pEntry : "";

        return axios.get(process.env.REACT_APP_URL_API+ 'Query/transactions/' + entryPoint ,{
            headers: {
              'requestToken': encryptStorage.getItem('pToken'),
            },
            params: params
            })
            .then(res => {
                this.setCustomerLastPayments(res.data.Records);   
            })
            .catch(error => {
                throw error;
        });
    }

    @action
    setCustomerLastPayments(data){
        this.customerLastPayments = data;   
    }

    @action
    setCustomer(data){
        this.customer = data;
    }
    
    @computed
    get customerAutopayCount(){
        return this.customer ? this.customer.Subscriptions.length : 0;
    }

    @computed
    get customerAutopayAmount(){
        let total = 0;
        if(this.customer){
            this.customer.Subscriptions.forEach(function (item) {
                total = total + item.TotalAmount;
            });
        }

        return total;
    }

    @computed
    get customerPaymentYDT(){
        let total = 0;
        if(this.statistics){
            this.statistics.forEach(function (item) {
                total = total + item.inTransactionsVolume;
            });
        }
        return total;
    }

    @computed
    get customerDataChart(){
        let data = [];
        data['labels'] = [];
        data['values'] = [0,0,0,0,0,0,0,0,0,0,0,0];
        data['values_position'] = [];
        

        for (let i = 12; i >= 1; i--) {
            let date = this.createDate(0, i - 12, 0);
            
            data['values_position'][i] = date.getFullYear() + '-' + (date.getMonth()+1);
            data['labels'][i] = this.getMonthName(date.getMonth(), 1) + " " + date.getFullYear().toString().substr(-2);
        }

        if(this.statistics){
            this.statistics.forEach(function (item) {
                let index = data['values_position'].indexOf(item.statX);
                if(index > -1){
                    data['values'][index] = item.inTransactionsVolume;
                }
            });
        }
        
        data['lastMonthTotal'] = this.statistics && this.statistics[this.statistics.length -1] ? this.statistics[this.statistics.length -1].inTransactionsVolume : 0;

        return data;
    }

    getMonthName(number, short){
        
        let monthNames = [];
        if(short){
            monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
            ];
        }
        else{
            monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
            ];
        }
        
        return monthNames[number];
    }

    createDate(days, months, years) {
        var date = new Date(); 
        date.setDate(date.getDate() + days);
        date.setMonth(date.getMonth() + months);
        date.setFullYear(date.getFullYear() + years);
        return date;    
    }

    @computed
    get customerStatusText(){
        if(this.customer){
            switch(this.customer.customerStatus){
                case 1:
                    return "Active";
                case -1:
                    return "Non-Authorized";
                case 58:
                    return "Pending";
                case 0:
                    return "Unregistered";
                default:
                    return "Unknow";
            }
        }
        return null;
       
    }

}

const customerStore = new CustomerStore();
export default customerStore;