import React from 'react';
import visa from '../assets/images/visa.svg';
import mastercard from '../assets/images/mastercard.svg';
import amex from '../assets/images/amex.svg';
import discover from '../assets/images/discover.svg';
import { inject, observer } from 'mobx-react';

@inject('vTerminal')
@observer
class PaymentMethodsAccepted extends React.Component {
    render() {
        return (
            <div>
                {this.props.vTerminal.paymentPage.paymentMethods.methods.visa !== false && <img alt="" src={visa} /> }
                {this.props.vTerminal.paymentPage.paymentMethods.methods.mastercard !== false  && <img alt="" className="mastercard" src={mastercard} />}
                {this.props.vTerminal.paymentPage.paymentMethods.methods.discover !== false  && <img alt="" className="discover" src={discover} />}
                {this.props.vTerminal.paymentPage.paymentMethods.methods.amex !== false  && <img alt="" className="amex" src={amex} />}
            </div>
        );
    }
}

export { PaymentMethodsAccepted };